import { render, staticRenderFns } from "./InputTextArea.vue?vue&type=template&id=24ac7942&scoped=true&style=font-size%3A12px%3B&"
import script from "./InputTextArea.vue?vue&type=script&lang=js&"
export * from "./InputTextArea.vue?vue&type=script&lang=js&"
import style0 from "./InputTextArea.vue?vue&type=style&index=0&id=24ac7942&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24ac7942",
  null
  
)

export default component.exports