<template style="font-size:12px;">
    <label class="w-100 m-0 ">
        <b>{{label}}</b>
        <textarea class="input-textarea"
                  cols="100"
                  rows="3"
                  :value="value"
                  @input="$emit('input', $event.target.value)">
        </textarea>
    </label>
</template>

<script>
    export default {
        name: 'InputTextArea',
        props: {
            label: { type: String, default: '' },
            value: { type: String, default: '' }
        }
    }
</script>

<style scoped>
    .input-textarea {
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
        resize: vertical;
    }
</style>