<template>
    <div class="d-inline-block">
        <i v-bind:class="[classeIcone, classeEstilo]"
           :style="{color: cor, fontSize: size}" 
           :title="title"
           @click="$emit('click')">
        </i>
    </div>
</template>

<script>
    export default {
        name: 'BotaoIconeDinamico',
        props: {
            cor: { String, default: 'black' },
            size: { String, default: '1rem' },
            title: { String, default: '' },
            classeIcone: { String, default: '' },
            classeEstilo: { String, default: 'px-2' },
        }
    }
</script>

<style scoped>
    i {
        cursor: pointer;
    }

        i.fas {
            font-size: .8rem;
        }
</style>