<template>
    <b-modal v-model="exibe"
             hide-footer centered
             title="Cliente"
             size="lg">
        <template v-slot:default="{ cancel, ok }">
            <form id="modal-agendamento-form"
                  @submit.prevent="salvar(ok)">
                <div class="container">
                    <!--#region Cadastro Basico  -->
                    <div class="row" v-if="tipoCadastro">
                        <div class="col-sm">
                            <div style="margin-top:24px;">
                                <input-checkbox v-model="ativo" />
                                <input-padrao class="mt-1" label="Razão Social" v-model.trim="razaoSocial" />
                                <input-padrao class="mt-1" label="Endereço" v-model.trim="endereco" />
                                <input-padrao class="mt-1" label="CEP" v-model.trim="cep" />
                                <input-padrao class="mt-1" label="Email *" v-model.trim="email" />
                            </div>

                        </div>
                        <div class="col-sm">

                            <input-padrao class="mt-1" label="Origem do contato" v-model.trim="origemContato" />
                            <input-padrao class="mt-1" label="CPF/CNPJ *" v-model.trim="cnpjCpf" />
                            <input-padrao class="mt-1" label="Complemento" v-model.trim="bairro" />
                            <input-padrao class="mt-1" label="Cidade" v-model.trim="municipio" />
                            <input-padrao class="mt-1" label="Estado" v-model.trim="uf" />
                            <input-padrao class="mt-1" label="Telefone *" v-model.trim="telefone" />
                        </div>
                    </div>

                    <!--#endregion -->
                    <!--#region Cadastro Completo -->
                    <div class="row" v-else>
                        <div class="col-sm">

                            <input-checkbox v-model="ativo" />

                            <input-select v-if="exibeCamposExtras" class="mr-4 mt-1"
                                          label="Situação Cliente"
                                          v-model.trim="situacaoCliente"
                                          :opcoes="[
                                                { text: 'PADRÃO', value: 0 },
                                                { text: 'PROSPECÇÃO', value: 1 }]" />

                            <input-select v-if="exibeCamposExtras" class="mr-4 mt-1"
                                          label="Status"
                                          v-model.trim="aberto"
                                          :opcoes="[
                                                  { text: 'Aberto', value: true },
                                                  { text: 'Fechado', value: false }]" />

                            <input-padrao class="mt-1" label="CPF/CNPJ *" v-model.trim="cnpjCpf" />

                            <input-padrao class="mt-1" label="Site" v-model.trim="site" />

                            <input-padrao class="mt-1" label="Ramo" lista="listaRamos" v-model.trim="ramo" />
                            <datalist id="listaRamos">
                                <option v-for="ramoAtividade in ramosAtividades">{{ramoAtividade}}</option>
                            </datalist>

                            <input-padrao class="mt-1" label="Endereço" v-model.trim="endereco" />

                            <input-padrao class="mt-1" label="Complemento" v-model.trim="bairro" />

                            <input-padrao class="mt-1" label="CEP" v-model.trim="cep" />

                            <input-padrao class="mt-1" label="Cidade" v-model.trim="municipio" />

                            <input-padrao class="mt-1" label="Estado" v-model.trim="uf" />
                            <input-padrao class="mt-1" label="Telefone *" v-model.trim="telefone" />
                            <input-padrao class="mt-1" label="Email *" v-model.trim="email" />

                            <!--<input-padrao class="mt-1" label="Logo" tipo="file" />-->

                            <input-padrao v-if="exibeCamposExtras" class="mt-1" label="Data de Retorno" tipo="date" v-model.trim="dataRetorno" />

                            <input-text-area class="mt-1" label="Observações" v-model="observacoes" />
                        </div>
                        <div class="col-sm">

                            <div id="container-contatos">
                                <div class="d-flex align-items-baseline">
                                    <h6 class="mb-0 mr-auto">Contatos</h6>
                                    <div>
                                        <b-dropdown size="sm" variant="link" dropleft toggle-class="text-decoration-none p-0" ref="dropdownContato" no-caret>
                                            <template #button-content>
                                                <BotaoIconeDinamico classeIcone="fas fa-plus" classeEstilo="p-0" @click="abrirDropdownContato()" />
                                            </template>
                                            <div class="px-4" style="width: 18rem;">
                                                <input-padrao class="mb-1" label="Nome" v-model.trim="contato.Nome" />
                                                <input-padrao class="mb-1" label="Cargo" v-model.trim="contato.Cargo" />
                                                <input-padrao class="mb-1" label="E-mail" v-model.trim="contato.Email" />
                                                <input-padrao class="mb-2" label="Telefone" v-model.trim="contato.Telefone" />

                                                <div class="d-flex justify-content-end">
                                                    <botao-modal-cancel type="button" class="float-right ml-2" @click="$refs.dropdownContato.hide(true)"> Cancelar </botao-modal-cancel>
                                                    <botao-modal-ok type="button" class="float-right ml-2 btn-sm" @click="salvarContato">
                                                        Salvar
                                                    </botao-modal-ok>
                                                </div>
                                            </div>
                                        </b-dropdown>
                                    </div>
                                </div>
                                <div class="border bg-light overflow-auto p-1" style="height:120px;">
                                    <div v-for="(contato, index) in contatos" :key="index" class="lista-contatos mb-1">
                                        <div class="d-flex align-items-center">
                                            <BotaoIconeDinamico classeIcone="fas fa-angle-down" size=".85rem" v-b-toggle="'collapse-contato-' + index" />
                                            <small class="mr-auto"><strong>{{contato.Nome}}</strong> - {{contato.Cargo}}</small>
                                            <BotaoIconeDinamico classeIcone="fas fa-pen" size=".65rem" @click="abrirDropdownContato(index)" />
                                            <BotaoIconeDinamico classeIcone="fas fa-trash" size=".65rem" @click="removerContato(index)" />
                                        </div>
                                        <b-collapse :id="'collapse-contato-' + index" class="collapse-contato">
                                            <small class="pl-4">E-mail: {{contato.Email}}</small><br />
                                            <small class="pl-4">Tel: {{contato.Telefone}}</small>
                                        </b-collapse>
                                    </div>
                                </div>
                            </div>

                            <div id="container-representante" class="mt-3">
                                <h6 class="mb-1">Representante</h6>
                                <div class="border bg-light px-1" style="height:30px;">
                                    <div>
                                        <div class="d-flex align-items-center">
                                            <span class="mr-auto">{{representante ? representante.Nome : ''}}</span>

                                            <b-dropdown size="sm" variant="link" offset="-295" toggle-class="text-decoration-none p-0 pt-1" ref="dropdownRepresentantes" no-caret>
                                                <template #button-content>
                                                    <BotaoIconeDinamico classeIcone="fas fa-chevron-down" size=".70rem" />
                                                </template>
                                                <div class="px-2" style="width: 22rem;">
                                                    <div>
                                                        <input-padrao class="mb-1" label="Busca" v-model.trim="nomeRepresentanteBusca" />
                                                        <div class="border overflow-auto bg-light pl-1" style="height: 150px;">
                                                            <p v-for="rep in listaRepresentantes"
                                                               :class="rep.Id === representanteSelecionadoId ? 'bg-primary text-white' : ''"
                                                               class="mb-0 px-1"
                                                               @click="representanteSelecionadoId = rep.Id">
                                                                {{rep ? rep.Nome : ''}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex justify-content-end mt-1">
                                                        <botao-modal-cancel type="button" class="float-right ml-2" @click="$refs.dropdownRepresentantes.hide(true)"> Cancelar </botao-modal-cancel>
                                                        <botao-modal-ok type="button" class="float-right ml-2 btn-sm" @click="salvarRepresentante">
                                                            Salvar
                                                        </botao-modal-ok>
                                                    </div>
                                                </div>
                                            </b-dropdown>

                                            <BotaoIconeDinamico v-if="representante" classeIcone="fas fa-trash" size=".65rem" @click="removerRepresentante()" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="container-comercial" class="mt-3">
                                <h6 class="mb-0 text-center">Comercial</h6>
                                <input-select label="Comercial"
                                              v-model.trim="usuarioComercial"
                                              :opcoes="listaUsuariosComercial" />
                            </div>

                            <div id="container-contrato" class="mt-3">
                                <h6 class="mb-0 text-center">Modelo de Contrato</h6>
                                <input-select class="mr-4"
                                              label="Tipo"
                                              v-model.trim="modeloContrato"
                                              :opcoes="[{ text: 'Hunting', value: 'hunting'}, { text: 'Outsourcing', value: 'outsourcing' }]" />

                                <input-select v-show="modeloContrato === 'hunting'"
                                              class="mt-1"
                                              label="Contrato (comissão)"
                                              v-model="contrato"
                                              :opcoes="porcentagensHunting" />

                                <input-padrao v-show="modeloContrato === 'outsourcing'"
                                              class="mt-1"
                                              label="Contrato (link)"
                                              v-model.trim="contrato" />

                                <input-select class="mr-4 mt-1"
                                              label="Modelo de Cobrança"
                                              v-model.trim="modeloCobranca"
                                              :opcoes="[
                                                { text: 'Selecione', value: 'null' },
                                                { text: 'PIX', value: 'pix'},
                                                { text: 'Boleto', value: 'boleto' },
                                                { text: 'Transferência Bancária', value: 'transferencia' }]" />

                                <b-card class="text-center h-auto shadow-none my-2" v-if="modeloCobranca != undefined && modeloCobranca != 'null' && modeloCobranca != 'boleto'">
                                    <div v-if="modeloCobranca == 'transferencia'" class="my-1">
                                        <h6 class="mb-1">Dados Bancário</h6>
                                        <b-input-group>
                                            <input-padrao label="Nome do Banco"
                                                          v-model.trim="nomeBanco"
                                                          class="mt-1" />
                                            <input-padrao label="Número da Agência"
                                                          v-model.trim="numeroAgencia"
                                                          class="mt-1" />
                                            <input-padrao label="Número da Conta"
                                                          v-model.trim="numeroConta"
                                                          class="mt-1" />
                                        </b-input-group>
                                    </div>

                                    <div v-if="modeloCobranca == 'pix' && modeloCobranca != undefined" class="my-1">
                                        <h6 class="mb-0 text-center">Dados PIX</h6>
                                        <input-select class="mr-4 mt-1"
                                                      label="Tipo de Chave PIX"
                                                      v-model.trim="tipoChavePix"
                                                      :opcoes="[
                                                    { text: 'Selecione', value: 'null' },
                                                    { text: 'CPF', value: 'cpf' },
                                                    { text: 'Telefone', value: 'telefone' },
                                                    { text: 'ID', value: 'id' }]" />
                                    </div>

                                    <input-padrao class="mr-4 mt-1"
                                                  :label="`Chave PIX (${tipoChavePix})`"
                                                  v-model.trim="chavePix"
                                                  v-if="tipoChavePix != undefined && tipoChavePix != 'null' && modeloCobranca === 'pix'" />
                                </b-card>

                                <input-padrao class="mt-1" label="Data de Pagamento" tipo="text" v-model.trim="dataPagamentoTexto" />
                                <input-padrao class="mt-1" label="Garantia"  v-model.trim="garantia" />
                                <input-percent class="mt-1" label="Multa por Atraso Pagamento"  v-model="multaAtrasoPagamento" />

                            </div>
                        </div>
                    </div>
                    <!--#endregion -->
                    <div class="row py-2">
                        <div class="col-6">
                            <ul class="modal-cliente-lista-erros">
                                <li v-for="(erro, i) in erros" :key="i">{{ erro }}</li>
                            </ul>
                        </div>
                        <div class="col-6">
                            <botao-modal-ok type="submit" class="float-right ml-2">
                                Salvar
                            </botao-modal-ok>
                            <botao-modal-cancel type="button" class="float-right ml-2" @click="cancel()"> Cancelar </botao-modal-cancel>
                        </div>
                    </div>

                </div>

            </form>

        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">

            </div>
        </template>

    </b-modal>
</template>

<script>
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue';
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue';
    import BotaoIconeDinamico from '@/components/botoes/BotaoIconeDinamico.vue';
    import InputPadrao from '@/components/inputs/InputPadrao.vue';
    import InputTextArea from '@/components/inputs/InputTextArea.vue';
    import InputSelect from '@/components/inputs/InputSelect.vue';
    import InputPercent from '@/components/inputs/InputPercent.vue';
    import InputCheckbox from '@/components/inputs/InputCheckbox.vue';

    import clienteServices from '@/assets/js/services/ClienteServices.js'
    import representanteServices from '@/assets/js/services/RepresentanteServices.js'
    import listagemDataset from '@/assets/datasets/listagemDataset.js'
    import { mapActions } from 'vuex'

    const porcentagensHunting = [
        { text: '10%', value: '10%' },
        { text: '20%', value: '20%' },
        { text: '30%', value: '30%' },
        { text: '40%', value: '40%' },
        { text: '50%', value: '50%' },
        { text: '60%', value: '60%' },
        { text: '70%', value: '70%' },
        { text: '80%', value: '80%' },
        { text: '90%', value: '90%' },
        { text: '100%', value: '100%' },
        { text: '120%', value: '120%' },
        { text: '150%', value: '150%' },
        { text: '200%', value: '200%' },
    ]
   
    export default {
        name: 'ModalCliente',

        components: {
            BotaoModalOk,
            BotaoModalCancel,
            BotaoIconeDinamico,
            InputPadrao,
            InputTextArea,
            InputSelect,
            InputPercent,
            InputCheckbox
        },

        props: {
            cliente: {
                type: Object,
                default: null
            },
            exibeCamposExtras: {
                type: Boolean,
                default: false
            },
        },

        data: function () {
            return {
                exibe: false,
                erros: [],

                id: 0,
                ativo: false,
                aberto: true,
                origemContato:'',
                razaoSocial: '',
                site: '',
                ramo: '',
                cnpjCpf: "",
                email: '',
                cargo: '',
                uf: '',
                municipio: '',
                bairro: '',
                cep: '',
                endereco: '',
                telefone: '',
                modeloContrato: '',
                contrato: '',
                modeloCobranca: '',
                dataPagamentoTexto: '',
                garantia: 0,
                multaAtrasoPagamento: 0,
                logo: '',

                dataRetorno: '',
                observacoes: '',
                modulosEmpresa: 0,
                tipoCadastro: 0,

                representante: '',
                usuarioComercial: '',
                contatos: [],

                contato: {
                    Nome: '',
                    Cargo: '',
                    Email: '',
                    Telefone: ''
                },
                representantes: [],
                nomeRepresentanteBusca: '',
                representanteSelecionadoId: '',

                situacaoCliente: 0,
                nomeBanco: '',
                numeroAgencia: '',
                numeroConta: '',
                tipoChavePix: '',
                chavePix: '',

                porcentagensHunting: porcentagensHunting
            }
        },

        watch: {
            modeloContrato(newValue, oldValue) {
                if (oldValue) this.contrato = '';
            },
            listaEmpresas() {
                return this.listaEmpresas();
            },
            empresaMod() {
                return this.empresaMod();
            }
        },

        computed: {

            ramosAtividades: () => listagemDataset.listarRamos(),
            listaRepresentantes() {
                

                if (this.nomeRepresentanteBusca) {
                    return this.representantes
                        .filter(representante =>
                            representante.Nome.toLowerCase().match(this.nomeRepresentanteBusca.toLowerCase()))
                }
                return this.representantes;
            },
            listaUsuariosComercial() {
                const ENUM_PERFIL_COMERCIAL = 5;
                const ENUM_PERFIL_GESTOR = 2;
                const listaUsuarios = this.$store.getters['usuarios/listaUsuarios']
                return listaUsuarios
                    .filter(usuario => usuario.Perfil === ENUM_PERFIL_COMERCIAL || usuario.Perfil === ENUM_PERFIL_GESTOR)
                    .map(u => { return { value: u.Id, text: u.Nome } })
            },
            empresaMod: function () {
                const mod = this.$store.getters['authentication/authDadosUsuario'];
                this.modulosEmpresa = mod.ModulosEmpresa == null ? "0" : mod.ModulosEmpresa;
                this.tipoCadastro = this.modulosEmpresa.includes('0')
            },
        },

        methods: {
            ...mapActions({
                mostrarErro: 'mostradorDeMensagens/mostrarErro'
            }),

            show() {
                
                this.ativo = false
                this.aberto = true
                this.dataRetorno = ''
                this.observacoes = ''
                this.razaoSocial = ''
                this.origemContato = ''
                this.site = ''
                this.ramo = ''
                this.cnpjCpf = ''
                this.email = ''
                this.telefone = ''
                this.uf = ''
                this.municipio = ''
                this.bairro = ''
                this.cep = ''
                this.endereco = ''
                this.telefone = ''
                this.modeloContrato = ''
                this.contrato = ''

                this.situacaoCliente = 0
                this.modeloCobranca = ''
                this.nomeBanco = ''
                this.numeroAgencia = ''
                this.numeroConta = ''
                this.tipoChavePix = ''
                this.chavePix = ''

                this.dataPagamentoTexto = ''
                this.garantia = 0
                this.multaAtrasoPagamento = 0
                this.logo = ''
                this.representante = ''
                this.contatos = []
                this.usuarioComercial = ''

                this.representanteSelecionadoId = this.cliente?.Representante?.Id

                this.exibe = true
            },
            hide() {
                this.exibe = false
            },
            abrirDropdownContato(indiceContato = null) {
                this.contato = { Nome: '', Cargo: '', Email: '', Telefone: '' };
                if (indiceContato != null) {
                    this.contato = this.contatos[indiceContato]
                    this.contato.indice = indiceContato
                }

                this.$refs.dropdownContato.show()
            },
            editar() {
                
                this.ativo = this.cliente?.Ativo ?? false
                this.aberto = this.cliente?.Aberto ?? true
                this.dataRetorno = this.cliente?.DataRetorno
                this.observacoes = this.cliente?.Observacoes
                this.razaoSocial = this.cliente?.RazaoSocial
                this.origemContato = this.cliente?.OrigemContato
                this.site = this.cliente?.Site
                this.ramo = this.cliente?.Ramo
                this.cnpjCpf = this.cliente?.CnpjCpf
                this.email = this.cliente?.Email
                this.telefone = this.cliente?.Telefone
                this.uf = this.cliente?.UF
                this.municipio = this.cliente?.Municipio
                this.bairro = this.cliente?.Bairro
                this.cep = this.cliente?.Cep
                this.endereco = this.cliente?.Endereco
                this.telefone = this.cliente?.Telefone
                this.modeloContrato = this.cliente?.ModeloContrato ?? ''
                this.contrato = this.cliente?.Contrato ?? ''

                this.situacaoCliente = this.cliente?.Situacao ?? 0
                this.modeloCobranca = this.cliente?.ModeloCobranca
                this.nomeBanco = this.cliente?.Banco
                this.numeroAgencia = this.cliente?.Agencia
                this.numeroConta = this.cliente?.NumeroConta
                this.tipoChavePix = this.cliente?.TipoChavePix
                this.chavePix = this.cliente?.ChavePix

                this.dataPagamentoTexto = this.cliente?.DataPagamentoTexto
                this.garantia = this.cliente?.Garantia
                this.multaAtrasoPagamento = this.cliente?.MultaAtraso
                this.logo = this.cliente?.LogoUrl
                this.representante = this.cliente?.Representante
                this.contatos = this.cliente?.Contatos ?? []
                this.usuarioComercial = this.cliente?.UsuarioId

                this.representanteSelecionadoId = this.cliente?.Representante?.Id

                this.exibe = true
            },
            salvarContato() {
                var contatoEdicao = this.contato.indice >= 0

                if (contatoEdicao) {
                    this.contatos[this.contato.indice] = this.contato

                    if (this.contato.Id)
                        clienteServices.updateContato(this.contato);
                } else {
                    this.contatos.push(this.contato);
                }

                this.$refs.dropdownContato.hide(true)
            },
            removerContato(indiceContato) {
                const contatoRemocao = this.contatos[indiceContato]
                if (window.confirm(`Tem certeza que deseja remover o contato de ${contatoRemocao?.Nome}?`)) {
                    this.contatos = this.contatos.filter((_, index) => index !== indiceContato)
                    if (contatoRemocao.Id)
                        clienteServices.deleteContatoById(contatoRemocao.Id);
                }
            },
            salvarRepresentante() {
                this.representante = this.representantes?.find(x => x.Id === this.representanteSelecionadoId);

                this.$refs.dropdownRepresentantes.hide(true)
                this.nomeRepresentanteBusca = ''
            },
            removerRepresentante() {
              

                if (window.confirm(`Term certeza que deseja remover o representante?`)) {
                    this.representanteSelecionadoId = ''
                    this.representante = null
                }
            },
            salvar(ok) {
                
                let valida = true;
                let msg = "Verifique os campos obrigatórios:";
                if (!this.cnpjCpf) {
                    msg = msg + ' CPF/CNPJ'
                    valida = false;
                }
               
                if (!valida) {
                    this.trataErro(msg);
                    return
                } else {
                    
                    const novoCliente = {
                        ...this.cliente,
                        Ativo: this.ativo,
                        Aberto: this.aberto,
                        DataRetorno: this.dataRetorno,
                        Observacoes: this.observacoes,
                        Site: this.site,
                        Ramo: this.ramo,
                        CnpjCpf: this.cnpjCpf,
                        Email: this.email,
                        UF: this.uf,
                        Municipio: this.municipio,
                        Bairro: this.bairro,
                        Cep: this.cep,
                        Endereco: this.endereco,
                        Telefone: this.telefone,
                        ModeloContrato: this.modeloContrato,
                        Contrato: this.contrato,
                        Situacao: this.situacaoCliente,
                        ModeloCobranca: this.modeloCobranca,
                        Banco: this.nomeBanco,
                        Agencia: this.numeroAgencia,
                        NumeroConta: this.numeroConta,
                        TipoChavePix: this.tipoChavePix,
                        ChavePix: this.chavePix,
                        DataPagamentoTexto: this.dataPagamentoTexto,
                        Garantia: this.garantia,
                        MultaAtraso: parseFloat(this.multaAtrasoPagamento),
                        LogoUrl: this.logo,
                        //Representante: this.representante?.Id,
                        RepresentanteId: this.representanteSelecionadoId,
                        Contatos: this.contatos,
                        UsuarioId: this.usuarioComercial,
                        RazaoSocial: this.razaoSocial,
                        OrigemContato: this.origemContato,
                    }
                    this.$emit('salvar', novoCliente)
                    ok()
                }
            },
            trataErro(msg) {
                this.$bvModal.msgBoxOk(msg, {
                    title: 'Algo deu errado',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Ok',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
            },
        },

        created() {
            //representanteServices.getAll()
            //    .then(res => { this.representantes = [...this.representantes, ...res] });
        },
    }
</script>

<style>
    .alinhamento-textos-center {
        text-align: center !important;
    }

    .modal-cliente-custom-body {
        padding: 0px 15px !important;
    }

    .modal-cliente-tabela {
        height: calc(100vh - 115px);
    }

    .modal-cliente-lista-erros {
        color: red;
    }

    .lista-contatos:hover {
        background-color: white;
        border: 0.5px solid black;
    }

    .collapse-contato {
        line-height: 16px;
        padding-bottom: 10px;
    }
    .erroLega {
        border-color:red;
    }
</style>