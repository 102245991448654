<template>
    <div class="container-checkbox d-inline-flex">
        <label class="checkbox-label">

            <input class="input-checkbox-custom align-self-center"
                   type="checkbox"
                   :checked="checked"
                   @change="$emit('change', $event.target.checked)">

            {{ label }}

        </label>
    </div>
</template>

<script>
    export default {
        name: 'InputCheckbox',
        model: {
            prop: 'checked',
            event: 'change'
        },
        props: {
            checked: Boolean,
            label: {String, default: 'Ativo'}
        }
    }
</script>

<style scoped>
    .container-checkbox label {
        display: grid;
        grid-template-columns: 1em auto;
        gap: 0.5em;
    }

    .container-checkbox input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
        margin: 0;
        width: 1.15em;
        height: 1.15em;
        border: .10em solid var(--cinza-4);
        display: grid;
        place-content: center;
    }

        .container-checkbox input[type="checkbox"]::before {
            content: "";
            width: .7em;
            height: .7em;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            background-color: var(--cor-primaria-cliente);
        }

        .container-checkbox input[type="checkbox"]:checked::before {
            transform: scale(1);
        }

</style>