<template>
    <div>
        <label class="w-100 m-0">
            {{label}}
            <percent-input 
                class="input-percent"
                suffix
                :value="value"
                @input="$emit('input', $event)">
            </percent-input>
        </label>
    </div>
</template>

<script>
    import { PercentInput } from 'vue-percent-input'

    export default {
        components: { PercentInput },
        props: {
            label: { String, default: 'Porcentagem' },
            value: { Number, default: 0 },
            prefixo: Boolean
        },
        methods: {
            print(v) {
                console.log(v)
            }
        }
    }
</script>


<style scoped>
    .input-percent {
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
    }
</style>